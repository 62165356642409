
import { Component, Vue } from 'vue-property-decorator';
import Menu from './peer-menu/Menu.vue';
import CustomizeTable from './peers-subpages/SectorPeers/customize-table/CustomizeTable.vue';
import CurrencyControl from './peers-subpages/SectorPeers/currency-control/CurrencyControl.vue';

@Component({
  components: {
    Menu,
    CustomizeTable,
    CurrencyControl,
  },
})
export default class PeerBenchmarkingPage extends Vue {
  sectorPeersTableType: 'cyfi' | 'charts' = 'cyfi';

  get showSectorPeersTableMenu(): boolean {
    return this.$route.name === 'SectorPeers';
  }

  get showCustomMenuItems(): boolean {
    return (
      this.sectorPeersTableType === 'cyfi' &&
      (this.$route.name === 'SectorPeers' || this.$route.name === 'CustomGroup')
    );
  }

  switchTableType(newval: 'cyfi' | 'charts') {
    this.sectorPeersTableType = newval;
  }

  get currentCompanyId() {
    return this.$store.getters.companyInfo.companyID;
  }
}
