
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { createChart, reorganizeChart } from './square-chart';

@Component
export default class PeersChart extends Vue {
  @Prop() private xDataName!: string;
  @Prop() private yDataName!: string;

  get chartDataValidation(): boolean {
    if (!this.$store.getters.overview_CyFiStats) return false;
    return true;
  }

  mounted() {
    if (!this.chartDataValidation) return;
    this.chartInit();
  }

  chartInit() {
    const chart_container = document.getElementById('peers-chart');
    let chart;
    if (chart_container) {
      chart = createChart(chart_container, [...this.peersData, ...this.curCompanyData]);
    }
  }

  @Watch('xDataName')
  updateXChartData() {
    if (!this.chartDataValidation) return;
    reorganizeChart([...this.peersData, ...this.curCompanyData]);
  }

  @Watch('yDataName')
  updateYChartData() {
    if (!this.chartDataValidation) return;
    reorganizeChart([...this.peersData, ...this.curCompanyData]);
  }

  get companyName() {
    return this.$store.state.CompanyModule.info.companyName;
  }

  get peersData() {
    const result = this.$store.getters.companySectorPeers;
    const x = result.map((el: any) => {
      if (isNaN(el[this.xDataName].value)) return 0;
      return el[this.xDataName].value;
    });
    const y = result.map((el: any) => {
      if (isNaN(el[this.yDataName].value)) return 0;
      return el[this.yDataName].value;
    });
    return [x, y];
  }

  get curCompanyData() {
    const currentCompany = {
      companyName: this.companyName,
      ...this.$store.getters.overview_CyFiStats,
    };
    let x = 0;
    if (!isNaN(currentCompany[this.xDataName].value)) x = currentCompany[this.xDataName].value;
    let y = 0;
    if (!isNaN(currentCompany[this.yDataName].value)) y = currentCompany[this.yDataName].value;
    return [[x], [y]];
  }
}
