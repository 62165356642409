
import { Component, Vue } from 'vue-property-decorator';

// @ts-ignore
import Autocomplete from '@trevoreyre/autocomplete-vue';
import '@trevoreyre/autocomplete-vue/dist/style.css';

@Component({
  components: { Autocomplete },
})
export default class HistoricalData extends Vue {
  dateDistribution: 'quarterly' | 'yearly' = 'quarterly';
  timeRangesList: string[] = ['YTD', '2021', '2020', '2019', '2018', '2017'];
  currentTimeRange: string = 'YTD';
  autoselect: boolean = true;

  switchTableType(newval: 'quarterly' | 'yearly') {
    this.dateDistribution = newval;
  }

  fields = [
    { key: 'totalAssets', title: 'Total Assets' },
    { key: 'intangibleAssets', title: 'Intangible Assets' },
    { key: 'earningAssets', title: 'Earning Assets' },
    { key: 'otherCurrentAssets', title: 'Other Current Assets' },
    { key: 'totalLiab', title: 'Total Liab' },
    { key: 'totalStockholderEquity', title: 'Total Stockholder Equity' },
    { key: 'deferredLongTermLiab', title: 'Deferred Long Term Liab' },
    { key: 'netDebt', title: 'Net Debt' },
    { key: 'shortTermDebt', title: 'Short Term Debt' },
    { key: 'shortLongTermDebtTotal', title: 'Short Long Term Debt Total' },
    { key: 'otherStockholderEquity', title: 'Other Stockholder Equity' },
    { key: 'propertyPlantEquipment', title: 'Property Plant Equipment' },
    { key: 'totalCurrentAssets', title: 'Total Current Assets' },
    { key: 'longTermInvestments', title: 'Long Term Investments' },
    { key: 'netTangibleAssets', title: 'Net Tangible Assets' },
    { key: 'shortTermInvestments', title: 'Short Term Investments' },
    { key: 'netReceivables', title: 'Net Receivables' },
    { key: 'longTermDebt', title: 'Long Term Debt' },
    { key: 'inventory', title: 'Inventory' },
    { key: 'accountsPayable', title: 'Accounts Payable' },
    { key: 'totalPermanentEquity', title: 'Total Permanent Equity' },
    {
      key: 'noncontrollingInterestInConsolidatedEntity',
      title: 'Noncontrolling Interest In Consolidated Entity',
    },
    {
      key: 'temporaryEquityRedeemableNoncontrollingInterests',
      title: 'Temporary Equity Redeemable Noncontrolling...',
    },
    { key: 'accumulatedOtherComprehensiveIncome', title: 'Accumulated Other Comprehensive Income' },
    { key: 'additionalPaidInCapital', title: 'Additional Paid In Capital' },
    { key: 'commonStockTotalEquity', title: 'Сommon Stock Total Equity' },
    { key: 'preferredStockTotalEquity', title: 'Preferred Stock Total Equity' },
    { key: 'retainedEarningsTotalEquity', title: 'Retained Earnings Total Equity' },
    { key: 'treasuryStock', title: 'Treasury Stock' },
    { key: 'accumulatedAmortization', title: 'Accumulated Amortization' },
    { key: 'nonCurrrentAssetsOther', title: 'Non Currrent Assets Other' },
    { key: 'deferredLongTermAssetCharges', title: 'Deferred Long Term Asset Charges' },
    { key: 'nonCurrentAssetsTotal', title: 'Non Currrent Assets Total' },
    { key: 'capitalLeaseObligations', title: 'Capital Lease Obligations' },
    { key: 'longTermDebtTotal', title: 'Long Term Debt Total' },
    { key: 'nonCurrentLiabilitiesOther', title: 'Non Current Liabilities Other' },
    { key: 'nonCurrentLiabilitiesTotal', title: 'Non Current Liabilities Total' },
    { key: 'negativeGoodwill', title: 'Negative Goodwill' },
    { key: 'warrants', title: 'Warrants' },
    { key: 'preferredStockRedeemable', title: 'Preferred Stock Redeemable' },
    { key: 'capitalSurpluse', title: 'Capital Surpluse' },
    { key: 'liabilitiesAndStockholdersEquity', title: 'Liabilities And Stockholders Equity' },
    { key: 'cashAndShortTermInvestments', title: 'Cash And Short Term Investments' },
    { key: 'propertyPlantAndEquipmentGross', title: 'Property Plant And Equipment Gross' },
    { key: 'accumulatedDepreciation', title: 'Accumulated Depreciation' },
    { key: 'netWorkingCapital', title: 'Net Working Capital' },
    { key: 'netInvestedCapital', title: 'Net Invested Capital' },
    { key: 'commonStockSharesOutstanding', title: 'Common Stock Shares Outstanding' },
  ];

  sortFieldsByStr = '';

  get balanceSheetArr() {
    const financials = this.$store.getters.companyFiData.Financials;
    const result = Object.entries(financials.Balance_Sheet[this.dateDistribution])
      .map((el) => {
        return [new Date(el[0]), el[1]];
      })
      .filter((el) => {
        if (this.currentTimeRange !== 'YTD') {
          // @ts-ignore
          return +this.currentTimeRange === el[0].getFullYear();
        }
        if (this.currentTimeRange === 'YTD') {
          let date = new Date(Date.now());
          date.setMonth(date.getMonth() - 12);
          // @ts-ignore
          return el[0] >= date;
        }
        return true;
      });
    return result;
  }

  get fieldNamesSorted() {
    if (this.sortFieldsByStr) {
      return this.fields.filter((f) => {
        return f.title.toLowerCase().includes(this.sortFieldsByStr.toLowerCase());
      });
    }
    return this.fields;
  }

  get tableNames(): any[] {
    return this.fieldNamesSorted.map((f) => f.title);
  }

  get tableDates(): any[] {
    // @ts-ignore
    return this.balanceSheetArr.map((row) => this.getQuarteredDate(row[0]));
  }

  getQuarteredDate(d: Date) {
    // For the US Government fiscal year
    // Oct-Dec = 1
    // Jan-Mar = 2
    // Apr-Jun = 3
    // Jul-Sep = 4
    d = d || new Date();
    const m = Math.floor(d.getMonth() / 3) + 2;
    const quarter = m > 4 ? m - 4 : m;
    if (this.dateDistribution === 'quarterly') return `Q${quarter} ` + d.getFullYear();
    return d.getFullYear();
  }

  get tableValues(): any[] {
    return this.fieldNamesSorted.map((field) => {
      return this.balanceSheetArr.map((row) => {
        return {
          // @ts-ignore
          value: +row[1][field.key],
          // @ts-ignore
          str: this.numberSeparator(row[1][field.key]),
        };
      });
    });
  }

  numberSeparator(number: string): string {
    if (!number) return 'null';
    return number.replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, `$&,`);
  }

  private formatCurrencyValue(value: number, dec: number = 2, power?: number): string {
    const postfix: string[] = ['', 'k', 'mn', 'bn', 'tn'];
    let counter: number = 0;
    let curValue = value;
    if (power) {
      curValue = curValue * 10 ** power;
    }
    let result: string = '';
    if (value < 0) {
      result = result + '-';
    }
    while (Math.abs(curValue) > 1000 && counter < postfix.length - 1) {
      curValue = curValue / 1000;
      counter = ++counter;
    }
    result = Math.abs(curValue).toFixed(dec);
    return result + ' ' + postfix[counter] + ' $';
  }
}
