
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Meta } from '@/types/meta';
import MetaLabel from '@/components/MetaLabel.vue';

@Component({
  components: {
    MetaLabel,
  },
})
export default class ScoreLabeled extends Vue {
  @Prop() valueMeta!: Meta | null;
  @Prop() label!: string;

  get value(): null | Meta['value'] {
    if (!this.valueMeta) return null;
    return this.valueMeta.value;
  }

  get displayedValue() {
    if (!this.value) return '-';
    try {
      const result = this.value.toFixed(1);
      return result;
    } catch {
      return this.value;
    }
  }

  setSectorScoreValueColor(value: any) {
    const period = isNaN(value) || value === null ? -1 : value;
    return {
      'score-values__value-red': period >= 0 && period < 3,
      'score-values__value-yellow': period >= 3 && period < 4,
      'score-values__value-green': period >= 4,
    };
  }

  setSectorScorePeriodColor(value: any) {
    const period = isNaN(value) || value === null ? -1 : value;
    return {
      'score-values__period-red': period >= 0 && period < 3,
      'score-values__period-yellow': period >= 3 && period < 4,
      'score-values__period-green': period >= 4,
    };
  }
}
