
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Meta } from '@/types/meta';

@Component
export default class StarBarMeta extends Vue {
  @Prop() private valueMeta!: Meta | null;
  @Prop() private mode!: string;

  private char: string = '★';
  private colorList: string[] = [
    'star-color1',
    'star-color2',
    'star-color3',
    'star-color4',
    'star-color5',
  ];

  get value() {
    if (!this.valueMeta) return 0;
    if (!isNaN(this.valueMeta.value)) {
      return this.valueMeta.value;
    }
    return 0;
  }

  get starBar(): string {
    let result = '';
    let starCount = this.value;
    if (starCount > 5) {
      starCount = 5;
    }
    for (let i = 0; i < Math.floor(starCount); i++) {
      result = result + this.char;
    }
    return result;
  }

  get starBarEnd(): string {
    let result = '';
    for (let i = 0; i < 5 - Math.floor(this.value); i++) {
      result = result + this.char;
    }

    if (this.mode == 'full') {
      return result;
    } else {
      return '';
    }
  }

  get color() {
    const className = this.colorList[Math.floor(this.value) - 1];
    return { [className]: true };
  }
}
