
import { Component, Vue, Watch } from 'vue-property-decorator';

// @ts-ignore
import CompanyMenu from '@/components/company-menu/CompanyMenu.vue';
import Alert from '@/components/Alert.vue';
import Preloader from '@/components/LoaderPage.vue';
import { companyRoutes } from '@/router/admin-routes/company-routes';

@Component({
  components: {
    Alert,
    CompanyMenu,
    Preloader,
  },
})
export default class CompanyPage extends Vue {
  visibleElement = 'loader is visible';

  get alertData() {
    return this.$store.getters.currentRaeAlert;
  }

  get routes() {
    return companyRoutes;
  }

  mounted() {
    this.loadData();
  }

  @Watch('$route')
  onRouteChanged(to: any, from: any) {
    if (to.params.companyId !== from.params.companyId) {
      this.loadData(true);
    } else {
      // this.loadData();
    }
  }

  loadData(isFirstLoad: boolean = false) {
    this.visibleElement = 'loader is visible';
    // @ts-ignore
    let id: string = this.$route.params.companyId;
    if (id !== 'undefined') {
      this.$store.dispatch('loadCompanyTabsData', id).then(() => {
        if (isFirstLoad) {
          this.goToFirstRoute(id);
        }
        this.visibleElement = 'table is visible';
        this.addViewedInfo();
        let title = 'Intangic CyFi™ Dashboard';
        if (this.$route.meta?.getTitle) {
          title = this.$route.meta.getTitle(this.$route.meta.title);
        }
        document.title = title;
      });
    } else {
    }
  }

  addViewedInfo() {
    const company = this.$store.getters.companyInfo;
    const fiData = this.$store.getters.companyFiData;
    let logoUrl = '';
    if (fiData && fiData.General && fiData.General.LogoURL) {
      logoUrl = 'https://eodhistoricaldata.com' + fiData.General.LogoURL;
    }
    let domen = '';
    if (fiData && fiData.General && fiData.General.WebURL) {
      domen = fiData.General.WebURL;
    }
    let info = {
      companyId: company.companyID,
      companyName: company.companyName,
      logoUrl: logoUrl,
      domen: domen,
    };
    this.$store.dispatch('appendViewedList', info);
  }

  get companyTitle(): string {
    return this.$store.getters.companyFullName;
  }

  get cyFiStats() {
    return this.$store.getters.overview_CyFiStats;
  }

  get underwritingStatus() {
    return this.cyFiStats?.UnderwritingStatus;
  }

  goToFirstRoute(companyId: string) {
    const route = companyRoutes.find((r: any) => {
      return !this.$store.getters.getDisabledCompanyRoutes.find(
        (routeName: string) => routeName === r.name
      );
    });
    if (route) {
      this.$router.push({ name: route.name, params: { companyId: companyId } }).catch((err) => {});
    }
  }

  toggleShowMeta() {
    const { path, query } = this.$route;
    if (query.meta) {
      this.$router
        .replace({ path: path, query: { ...this.$route.query, meta: undefined } })
        .catch((err) => {});
      return;
    }
    this.$router
      .replace({ path: path, query: { ...this.$route.query, meta: 'true' } })
      .catch((err) => {});
  }
}
