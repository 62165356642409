
import { Component, Vue } from 'vue-property-decorator';

import LogoutBtn from './LogoutBtn.vue';
import Menu from './HeaderMenu.vue';
// @ts-ignore
import Autocomplete from '@trevoreyre/autocomplete-vue';
import '@trevoreyre/autocomplete-vue/dist/style.css';
import { Company } from '@/types';

@Component({
  components: {
    LogoutBtn,
    Menu,
    Autocomplete,
  },
})
export default class Header extends Vue {
  autoselect: boolean = true;

  get hasSearchBar(): boolean {
    return !this.$store.getters.isRestrictedUser;
  }

  search(input: string) {
    if (input.length < 1) {
      return [];
    }
    const companyNames: Array<string> = this.$store.getters.allCompanies.map(
      (c: Company) => c.companyName
    );
    const result = companyNames.filter((cmp) => {
      return cmp.toLowerCase().includes(input.toLowerCase());
    });
    if (result.length !== 0) return result;
    return [];
  }

  async handleSubmit(result: string) {
    // @ts-ignore
    const res = result || this.$refs.searchInput.value;
    const cmp: Company | undefined = this.$store.getters.allCompanies.find(
      (el: Company) => el.companyName == res
    );
    if (cmp !== undefined) {
      this.$router.push({ path: `/admin/Company/${cmp.companyID}` }).catch((err) => {});
    }
  }

  get username(): string {
    return this.$store.getters.username;
  }
}
