
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MenuItem extends Vue {
  @Prop() pageName!: string;
  @Prop() path!: string;
  @Prop() title!: string;

  get currentCompanyId() {
    return this.$store.getters.companyInfo.companyID;
  }
}
