
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class PreviouslyViewed extends Vue {
  get list() {
    return this.$store.getters.previouslyViewedList;
  }

  goToApple() {
    this.$router.push({ path: `/admin/Company/HWUPKR0MPOU8FGXBT394_lei` }).catch((err) => {});
  }
}
