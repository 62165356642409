
import { Component, Vue } from 'vue-property-decorator';
import PeersAreaChart from './PeersAreaChart/PeersAreaChart.vue';
import PeersAreaChartLegend from './PeersAreaChartLegend/PeersAreaChartLegend.vue';

@Component({ components: { PeersAreaChart, PeersAreaChartLegend } })
export default class PeerBenchmarkingAreaPage extends Vue {
  get companyTitle() {
    return this.$store.getters.companyFullName as string;
  }
}
