
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class SubsidiariesGrid extends Vue {
  get SubsidiariesData(): any[] {
    return this.$store.getters.companySubsidiaries;
  }
}
