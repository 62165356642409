
import { Chart } from '@/libs/chart-lib';
import { Component, Vue } from 'vue-property-decorator';

import { create_rosie_xAxis_chart, changeRange as changeRange_axis } from './axis-chart';
import { createChart_1, changeRange as changeRange_1 } from './stock-price-chart';
import { createChart_2, changeRange as changeRange_2 } from './recon-chart';
import { createChart_3, changeRange as changeRange_3 } from './attack-chart';
import { createChart_4, changeRange as changeRange_4 } from './netacc-chart';

// @ts-ignore
import csvDownload from 'json-to-csv-export';

@Component
export default class ChartPanel extends Vue {
  private chart_axis: Chart | undefined = undefined;
  private chart_1: Chart | undefined = undefined;
  private chart_2: Chart | undefined = undefined;
  private chart_3: Chart | undefined = undefined;
  private chart_4: Chart | undefined = undefined;

  rangeMenu = [
    { title: '3m', selected: false },
    { title: '6m', selected: false },
    { title: '1y', selected: false },
    { title: 'All', selected: true },
  ];

  mounted() {
    if (!this.chartsDataIsEmpty) {
      this.chartsInit_once();
    } else {
      this.$store.dispatch('retryfetchStockPrice', this.$route.params.companyId).finally(() => {
        this.chartsInit_once();
      });
    }
  }

  get rangeOption() {
    return this.rangeMenu.find((item) => item.selected)?.title;
  }

  get dateArray(): Date[] {
    const stockDateList = this.$store.getters.stockPriceDataChart.map(
      (el: any) => new Date(el.date)
    );
    const cvDateList = this.$store.getters.overview_CyberValuesData.map(
      (el: any) => new Date(el.ChTimestamp)
    );
    const dateList = stockDateList.length === 0 ? cvDateList : stockDateList;
    if (!this.rangeOption || this.rangeOption === 'All') return dateList;
    const lastDate = dateList[dateList.length - 1];
    let minDate: Date = new Date();
    switch (this.rangeOption) {
      case '3m':
        minDate = new Date(new Date(lastDate.getTime()).setMonth(lastDate.getMonth() - 3));
        break;
      case '6m':
        minDate = new Date(new Date(lastDate.getTime()).setMonth(lastDate.getMonth() - 6));
        break;
      case '1y':
        minDate = new Date(new Date(lastDate.getTime()).setFullYear(lastDate.getFullYear() - 1));
        break;
    }
    return dateList.filter((el: Date) => {
      return el.getTime() >= minDate.getTime();
    });
  }

  selectRange(index: number) {
    this.rangeMenu.forEach((item, ind) => (item.selected = index === ind));
    if (this.chart_axis)
      changeRange_axis([this.axis_DateObj, this.axis_DateObj.map((el: any) => 0)]);
    if (this.chart_1) changeRange_1(this.stock_dataRow);
    if (this.chart_2) changeRange_2([this.forums_row, this.pastes_row, this.osint_row]);
    if (this.chart_3) changeRange_3([this.markets_row, this.exits_row]);
    if (this.chart_4) changeRange_4([this.clients_row]);
  }

  get chartsDataIsEmpty(): boolean {
    return this.stockPriceData.length === 0;
  }

  get chartsCyberDataIsEmpty(): boolean {
    return this.cyberValuesData.length === 0;
  }

  get commonData() {
    return {
      Recon: null,
      Attack: null,
      ...this.$store.getters.overview_CyFiStats,
    };
  }

  labelColorClass(value: number | null): string {
    if (value === null) {
      return 'square-label';
    }
    if (value >= 4 && value <= 5) {
      return 'square-label_green';
    }
    if (value >= 3 && value <= 4) {
      return 'square-label_yellow';
    }
    if (value >= 0 && value < 3) {
      return 'square-label_red';
    }
    return 'square-label';
  }

  // stock Price
  get stockPriceData() {
    // return [];
    const initialData = this.$store.getters.stockPriceDataChart;
    let startDate: Date;
    if (this.dateArray.length !== 0) {
      startDate = this.dateArray[0];
    } else {
      return [];
    }

    const rangedData = initialData.filter((el: any) => {
      const date = new Date(el.date);
      return date.getTime() >= startDate.getTime();
    });

    const benchmark = rangedData[0];
    const percent = (val: number, zero: number) => ((val - zero) / zero) * 100;
    const result = rangedData.map((el: any, ind: number) => {
      return {
        date: el.date,
        adjusted_close: el.adjusted_close,
        adjusted_close_percent: percent(el.adjusted_close, benchmark.adjusted_close),
        // close: percent(el.close, benchmark.close),
        // high: percent(el.high, benchmark.high),
        // low: percent(el.low, benchmark.low),
        // open: percent(el.open, benchmark.open),
      };
    });
    return result;
  }

  download(prefix: string, data: any[]) {
    const filename = prefix + this.$store.getters.companyInfo.companyID + '.csv';
    csvDownload(data, filename);
  }

  get stock_dataRow(): number[] {
    return this.stockPriceData.map((el: any) => el.adjusted_close_percent);
  }

  get stock_zeroRow(): number[] {
    return this.stockPriceData.map((el: any) => 0);
  }

  get stock_DateObj(): Date[] {
    return this.stockPriceData.map((el: any) => new Date(el.date));
  }

  get stock_DateString(): string[] {
    return this.stock_DateObj.map((el: Date) => el.toLocaleDateString('en'));
  }

  get cyberValuesData() {
    const initialData = this.$store.getters.overview_CyberValuesData;
    let startDate: Date;
    if (this.dateArray.length !== 0) {
      startDate = this.dateArray[0];
    } else {
      return [];
    }

    const rangedData = initialData.filter((el: any) => {
      const date = new Date(el.ChTimestamp);
      return date.getTime() >= startDate.getTime();
    });

    const res = [...rangedData];
    const zeroObj = {
      ChTimestamp: '',
      Clients: 0,
      Exits: 0,
      Forums: 0,
      Grouping: '',
      Markets: 0,
      Osint: 0,
      Pastes: 0,
    };
    while (res.length <= this.dateArray.length) {
      res.unshift(zeroObj);
    }
    return res;
  }

  get downloadForumsPastesOsint() {
    return this.cyberValuesData.map((el) => {
      return {
        ChTimestamp: el.ChTimestamp,
        Forums: el.Forums,
        Pastes: el.Pastes,
        Osint: el.Osint,
      };
    });
  }

  get downloadMarketsExits() {
    return this.cyberValuesData.map((el) => {
      return {
        ChTimestamp: el.ChTimestamp,
        Markets: el.Markets,
        Exits: el.Exits,
      };
    });
  }

  get downloadClients() {
    return this.cyberValuesData.map((el) => {
      return {
        ChTimestamp: el.ChTimestamp,
        Clients: el.Clients,
      };
    });
  }

  get cyberValues_DateObj(): Date[] {
    return this.cyberValuesData.map((el: any) => new Date(el.ChTimestamp));
  }

  get cyberValues_DateString(): string[] {
    return this.cyberValues_DateObj.map((el: Date) => el.toLocaleDateString('en'));
  }

  get axis_DateObj(): Date[] {
    return this.dateArray;
  }

  get forums_row() {
    return this.cyberValuesData.map((el: any) => el.Forums);
  }
  get pastes_row() {
    return this.cyberValuesData.map((el: any) => el.Pastes);
  }
  get osint_row() {
    return this.cyberValuesData.map((el: any) => el.Osint);
  }
  get markets_row() {
    return this.cyberValuesData.map((el: any) => el.Markets);
  }
  get exits_row() {
    return this.cyberValuesData.map((el: any) => el.Exits);
  }
  get clients_row() {
    return this.cyberValuesData.map((el: any) => el.Clients);
  }

  chartsInit_once() {
    const chart_container_axis = document.getElementById('rosie-charts_axis');
    if (chart_container_axis && this.axis_DateObj.length !== 0) {
      this.chart_axis = create_rosie_xAxis_chart(chart_container_axis, [
        this.axis_DateObj,
        this.axis_DateObj.map((el: any) => 0),
      ]);
    }

    const chart_container_1 = document.getElementById('rosie-charts_1');
    if (chart_container_1 && !this.chartsDataIsEmpty) {
      this.chart_1 = createChart_1(chart_container_1, [this.stock_DateString, this.stock_dataRow]);
    }

    const chart_container_2 = document.getElementById('rosie-charts_2'); // forums pastes osint
    if (chart_container_2 && !this.chartsCyberDataIsEmpty) {
      this.chart_2 = createChart_2(chart_container_2, [
        this.forums_row,
        this.pastes_row,
        this.osint_row,
      ]);
    }

    const chart_container_3 = document.getElementById('rosie-charts_3'); // markets exits
    if (chart_container_3 && !this.chartsCyberDataIsEmpty) {
      this.chart_3 = createChart_3(chart_container_3, [this.markets_row, this.exits_row]);
    }

    const chart_container_4 = document.getElementById('rosie-charts_4'); // clients
    if (chart_container_4 && !this.chartsCyberDataIsEmpty) {
      this.chart_4 = createChart_4(chart_container_4, [this.clients_row]);
    }

    if (this.chart_axis) {
      if (this.chart_1) this.chart_axis.bindOtherChartTooltips(this.chart_1);
      if (this.chart_2) this.chart_axis.bindOtherChartTooltips(this.chart_2);
      if (this.chart_3) this.chart_axis.bindOtherChartTooltips(this.chart_3);
      if (this.chart_4) this.chart_axis.bindOtherChartTooltips(this.chart_4);
    }

    if (this.chart_1) {
      if (this.chart_axis) this.chart_1.bindOtherChartTooltips(this.chart_axis);
      if (this.chart_2) this.chart_1.bindOtherChartTooltips(this.chart_2);
      if (this.chart_3) this.chart_1.bindOtherChartTooltips(this.chart_3);
      if (this.chart_4) this.chart_1.bindOtherChartTooltips(this.chart_4);
    }

    if (this.chart_2) {
      if (this.chart_axis) this.chart_2.bindOtherChartTooltips(this.chart_axis);
      if (this.chart_1) this.chart_2.bindOtherChartTooltips(this.chart_1);
      if (this.chart_3) this.chart_2.bindOtherChartTooltips(this.chart_3);
      if (this.chart_4) this.chart_2.bindOtherChartTooltips(this.chart_4);
    }

    if (this.chart_3) {
      if (this.chart_axis) this.chart_3.bindOtherChartTooltips(this.chart_axis);
      if (this.chart_1) this.chart_3.bindOtherChartTooltips(this.chart_1);
      if (this.chart_2) this.chart_3.bindOtherChartTooltips(this.chart_2);
      if (this.chart_4) this.chart_3.bindOtherChartTooltips(this.chart_4);
    }

    if (this.chart_4) {
      if (this.chart_axis) this.chart_4.bindOtherChartTooltips(this.chart_axis);
      if (this.chart_1) this.chart_4.bindOtherChartTooltips(this.chart_1);
      if (this.chart_2) this.chart_4.bindOtherChartTooltips(this.chart_2);
      if (this.chart_3) this.chart_4.bindOtherChartTooltips(this.chart_3);
    }
  }
}
