
import { Component, Vue } from 'vue-property-decorator';
// @ts-ignore
import ClickOutside from 'vue-click-outside';

@Component({ directives: { ClickOutside } })
export default class CustomizeTable extends Vue {
  showDropDown = false;
  preventClosing = false;
  sortFieldsByStr = '';

  toggleDropDown() {
    this.showDropDown = !this.showDropDown;
    this.preventClosing = true;
  }

  closeDropDown() {
    if (this.preventClosing) {
      this.preventClosing = false;
      return;
    }
    if (this.showDropDown) this.showDropDown = false;
  }

  get riskScoresOptions() {
    const fields = this.$store.getters.riskScoresOptions;
    if (this.sortFieldsByStr) {
      return fields.filter((f: any) => {
        return f.label.toLowerCase().includes(this.sortFieldsByStr.toLowerCase());
      });
    }
    return fields;
  }

  get fiDataTableOptions() {
    const fields = this.$store.getters.fiDataTableOptions;
    if (this.sortFieldsByStr) {
      return fields.filter((f: any) => {
        return f.label.toLowerCase().includes(this.sortFieldsByStr.toLowerCase());
      });
    }
    return fields;
  }

  updateValue(item: any) {
    this.$store.commit('setTableOptionsItem', item);
  }

  updateFiDataValue(item: any) {
    this.$store.commit('setHighlightsOptionsItem', item);
  }
}
