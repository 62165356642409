
import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header/Header.vue';
import ProgressBar from '@/components/ProgressBar.vue';

@Component({
  components: {
    Header,
    ProgressBar,
  },
})
export default class RootPage extends Vue {
  created() {
    this.$store.dispatch('loadInitialData', 'admin');
  }

  get loadedOnce() {
    return this.$store.getters.initialDataIsLoaded;
  }

  get progress() {
    return this.$store.getters.initialDataProgress;
  }
}
