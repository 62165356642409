
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ExecutiveSummaryRow } from '@/store/types/interfaces';
import VueSimplemde from 'vue-simplemde';

interface IMDEConfig {
  autofocus: boolean;
  toolbar: string[];
  status: boolean;
}

/**
 * Parse server data to editor string format
 * @param rawData Data from server
 */
function rawTextParser(rawData: ExecutiveSummaryRow): string {
  return rawData ? JSON.parse(rawData.EditorText).content : '';
}

/**
 * Default toolbar configuration
 */
function getToolbarDefaults(): string[] {
  return [
    'bold',
    'italic',
    'heading',
    'unordered-list',
    'ordered-list',
    'link',
    'preview',
    '|',
    'quote',
    'guide',
  ];
}

@Component({
  components: {
    VueSimplemde,
  },
})
export default class WrappedMarkdown extends Vue {
  @Prop() private readonly rawData!: any;
  content: string = '';
  componentKey: number = 0;
  private oldContent: string = '';
  configs: IMDEConfig = {
    autofocus: false,
    toolbar: getToolbarDefaults(),
    status: false,
  };
  isEditing = false;

  protected beforeMount(): void {
    this.content = rawTextParser(this.rawData);
    this.oldContent = this.content;
  }

  /**
   * Deactivates mde on outside click
   */
  deactivateEditor(): void {
    if (this.isEditing) {
      this.isEditing = false;
      if (this.content !== this.oldContent) {
        this.save();
      }
    }
  }

  /**
   * Activates MDE by click
   * @param state
   */
  activateEditor(): void {
    if (this.isEditing) {
      return;
    } else {
      this.oldContent = this.content;
    }
    this.isEditing = true;
  }

  /**
   * Shows confirmation window and saves data to AWS
   */
  private async save(): Promise<void> {
    if (!confirm('Stop edit and save?')) {
      this.content = this.oldContent;
      //ToDO v-model directive doesn't work with this mde component
      //@ts-ignore
      this.$refs.markdownEditor.simplemde.codemirror.doc.setValue(this.content);
    } else {
      await this.$store.dispatch('updateEditorData', {
        companyId: this.$store.getters.companyInfo.companyID,
        data: {
          content: this.content,
        },
      });
    }
  }
}
