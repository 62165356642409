
import { Component, Vue, Watch } from 'vue-property-decorator';
import ChartPanel from './chart-panel/ChartPanel.vue';
import WrappedMarkdown from './mark-down-editor/WrappedMarkdown.vue';
import TooltipIcon from '@/components/TooltipIcon.vue';
import Indicator from '@/components/IndicatorMeta.vue';
import ScoreLabeled from '@/components/ScoreLabeledMeta.vue';
import StarBar from '@/components/StarBarMeta.vue';
import PeersChart from './peers-chart/PeersChart.vue';
import ErrorBoundary from '@/components/ErrorBoundary.vue';

function formatDate(date: Date): string {
  return new Date(date).toLocaleString('ru');
}

@Component({
  components: {
    ChartPanel,
    Indicator,
    ScoreLabeled,
    StarBar,
    PeersChart,
    WrappedMarkdown,
    TooltipIcon,
    ErrorBoundary,
  },
})
export default class OverviewPage extends Vue {
  public xDataName = 'Recon';
  public yDataName = 'OperatingEfficiency';

  selectXData(newVal: string) {
    this.xDataName = newVal;
  }

  selectYData(newVal: string) {
    this.yDataName = newVal;
  }

  toNumber = (value: string): number => {
    if (isNaN(+value)) return 0;
    return +value;
  };

  get factorsData() {
    return {
      SectorScore_12ma: null,
      SectorScore: null,
      ...['_24ma', '_12ma', ''].reduce((prev, cur) => ({ ...prev, [`Recon${cur}`]: null }), {}),
      ...['_24ma', '_12ma', ''].reduce((prev, cur) => ({ ...prev, [`Attack${cur}`]: null }), {}),
      ...['_24ma', '_12ma', ''].reduce((prev, cur) => ({ ...prev, [`Exfil${cur}`]: null }), {}),
      ...['_24ma', '_12ma', ''].reduce(
        (prev, cur) => ({ ...prev, [`OperatingEfficiency${cur}`]: null }),
        {}
      ),
      ...['_24ma', '_12ma', ''].reduce(
        (prev, cur) => ({ ...prev, [`CapitalEfficiency${cur}`]: null }),
        {}
      ),
      ...['_24ma', '_12ma', ''].reduce(
        (prev, cur) => ({ ...prev, [`FinancialExposure${cur}`]: null }),
        {}
      ),
      ...['_24ma', '_12ma', ''].reduce(
        (prev, cur) => ({ ...prev, [`CapitalManagement${cur}`]: null }),
        {}
      ),
      ...['_24ma', '_12ma', ''].reduce(
        (prev, cur) => ({ ...prev, [`Productivity${cur}`]: null }),
        {}
      ),
      ...this.$store.getters.overview_CyFiStats,
    };
  }

  get executiveSummaryData() {
    return this.$store.getters.executiveSummaryData;
  }

  get summaryRevisions() {
    const sortedList = this.$store.getters.executiveSummarySortedList;
    return sortedList
      .slice(0, 3)
      .map((value: any) => `${value.UserLogin},  ${formatDate(value.ChTimestamp)}`);
  }
  get summaryRevisionsHTML(): string {
    return this.summaryRevisions.join('<br>');
  }

  get updateDate() {
    if (this.executiveSummaryData) {
      return formatDate(this.executiveSummaryData.ChTimestamp);
    }
    return null;
  }
}
