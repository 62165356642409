
import { Component, Vue } from 'vue-property-decorator';

import MenuItem from './HeaderMenuItem.vue';

@Component({
  components: {
    MenuItem,
  },
})
export default class HeaderMenu extends Vue {
  get menuData() {
    return [];
  }
}
