
import { Component, Vue } from 'vue-property-decorator';
import EditorJS from '@editorjs/editorjs';
// @ts-ignore
import List from '@editorjs/list';

@Component
export default class ExecutiveSummaryEdit extends Vue {
  private editor: any;
  isSaving: boolean = false;
  selectedItem: number = -1;

  mounted() {
    // @ts-ignore
    this.$refs.modal.focus();
    this.initEditor();
  }

  get executiveSummarySortedList() {
    return this.$store.getters.executiveSummarySortedList;
  }

  selectItem(index: number) {
    this.selectedItem = index;
    this.editor.render(JSON.parse(this.executiveSummarySortedList[index].EditorText));
  }

  initEditor() {
    const config: any = {
      holder: 'editorjs',
      tools: {
        list: {
          class: List,
          inlineToolbar: true,
        },
      },
    };
    if (this.executiveSummarySortedList.length >= 1) {
      const data = JSON.parse(this.executiveSummarySortedList[0].EditorText);
      this.selectedItem = 0;
      config.data = data;
    }
    this.editor = new EditorJS(config);
  }

  save() {
    this.isSaving = true;
    this.editor
      .save()
      .then((outputData: any) => {
        return this.$store.dispatch('updateEditorData', {
          companyId: this.$store.getters.companyInfo.companyID,
          data: outputData,
        });
      })
      .then(() => {
        this.selectItem(0);
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {
        this.isSaving = false;
      });
  }

  goBack() {
    this.$router.back();
  }

  get currentCompanyName() {
    return this.$store.getters.companyInfo.companyName;
  }

  get currentCompanyId() {
    return this.$store.getters.companyInfo.companyID;
  }
}
