
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CompanyMenuItem extends Vue {
  @Prop() pageName!: string;
  @Prop() path!: string;
  @Prop() title!: string;

  get isDisabled() {
    const result = this.$store.getters.getDisabledCompanyRoutes.find(
      (routeName: string) => routeName === this.pageName
    );
    return result;
  }

  get currentCompanyId() {
    return this.$store.getters.companyInfo.companyID;
  }
}
