
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class SubsidiariesTable extends Vue {
  private sortedField = 'name';
  private sortedDirection: 'up' | 'down' = 'down';

  public changeSort(field: string) {
    if (this.sortedField === field) {
      this.sortedDirection = this.sortedDirection === 'up' ? 'down' : 'up';
    } else {
      this.sortedField = field;
      this.sortedDirection = 'up';
    }
  }

  get sortedTable() {
    const initTable = [...this.tableData];

    initTable.sort((a: any, b: any) => {
      if (a[this.sortedField] > b[this.sortedField]) {
        return this.sortedDirection === 'up' ? 1 : -1;
      }
      if (a[this.sortedField] < b[this.sortedField]) {
        return this.sortedDirection === 'down' ? 1 : -1;
      }
      return 0;
    });
    return initTable;
  }

  get tableData(): any[] {
    return this.$store.getters.companySubsidiaries;
  }
}
