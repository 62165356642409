
import { Component, Vue } from 'vue-property-decorator';
import Table from './tables/CyDataTable.vue';
import TableGraph from './tables/CyDataTableGraph.vue';

@Component({
  components: {
    Table,
    TableGraph,
  },
})
export default class CyDataPage extends Vue {
  tableType: 'numbers' | 'graphs' = 'numbers';

  switchTableType(newval: 'numbers' | 'graphs') {
    this.tableType = newval;
  }

  get tableComponent(): string {
    if (this.tableType === 'graphs') return 'TableGraph';
    return 'Table';
  }
}
