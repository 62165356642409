
import { Component, Vue } from 'vue-property-decorator';
import LevelBar from '@/components/LevelBar.vue';
import { CyRow } from '@/types';

@Component({
  components: {
    LevelBar,
  },
})
export default class CyDataTableGraph extends Vue {
  private headersTop: string[] = ['Reconnaissance', 'Attack', 'Network Access'];
  private headers: string[] = ['Forums', 'Pastes', 'OSINT', 'Markets', 'Exits'];
  private tableExtremes?: CyRow;

  private colorScheme = {
    low: {
      stroke: '#3D894C',
      fill: '#32583A',
      strokeFade: '#414141',
      text: '#3D894C',
      textFade: '#434343',
    },
    mid: {
      stroke: '#D1BC84',
      fill: '#423D31',
      strokeFade: '#414141',
      text: '#ECBA44',
      textFade: '#434343',
    },
    high: {
      stroke: '#D93F30',
      fill: '#652F2A',
      strokeFade: '#414141',
      text: '#D93F30',
      textFade: '#434343',
    },
  };

  created() {
    this.countTableExtremes();
  }

  get tableData(): CyRow[] {
    return this.$store.getters.cyDataListWithAlerts;
  }

  countTableExtremes() {
    const result: CyRow = {
      companyID: 'Max',
      timeStamp: new Date(),
      pages: {
        low: Math.max.apply(
          null,
          this.tableData.map((row: CyRow) => row.pages.low)
        ),
        mid: Math.max.apply(
          null,
          this.tableData.map((row: CyRow) => row.pages.mid)
        ),
        high: Math.max.apply(
          null,
          this.tableData.map((row: CyRow) => row.pages.high)
        ),
      },
      markets: {
        low: Math.max.apply(
          null,
          this.tableData.map((row: CyRow) => row.markets.low)
        ),
        mid: Math.max.apply(
          null,
          this.tableData.map((row: CyRow) => row.markets.mid)
        ),
        high: Math.max.apply(
          null,
          this.tableData.map((row: CyRow) => row.markets.high)
        ),
      },
      forums: {
        low: Math.max.apply(
          null,
          this.tableData.map((row: CyRow) => row.forums.low)
        ),
        mid: Math.max.apply(
          null,
          this.tableData.map((row: CyRow) => row.forums.mid)
        ),
        high: Math.max.apply(
          null,
          this.tableData.map((row: CyRow) => row.forums.high)
        ),
      },
      pastes: {
        low: Math.max.apply(
          null,
          this.tableData.map((row: CyRow) => row.pastes.low)
        ),
        mid: Math.max.apply(
          null,
          this.tableData.map((row: CyRow) => row.pastes.mid)
        ),
        high: Math.max.apply(
          null,
          this.tableData.map((row: CyRow) => row.pastes.high)
        ),
      },
      exits: {
        low: Math.max.apply(
          null,
          this.tableData.map((row: CyRow) => row.exits.low)
        ),
        mid: Math.max.apply(
          null,
          this.tableData.map((row: CyRow) => row.exits.mid)
        ),
        high: Math.max.apply(
          null,
          this.tableData.map((row: CyRow) => row.exits.high)
        ),
      },
      osint: {
        low: Math.max.apply(
          null,
          this.tableData.map((row: CyRow) => row.osint.low)
        ),
        mid: Math.max.apply(
          null,
          this.tableData.map((row: CyRow) => row.osint.mid)
        ),
        high: Math.max.apply(
          null,
          this.tableData.map((row: CyRow) => row.osint.high)
        ),
      },
      clients: Math.max.apply(
        null,
        this.tableData.map((row: CyRow) => row.clients)
      ),
    };
    this.tableExtremes = result;
  }

  get yearCol(): string[] {
    const years: string[] = this.tableData.map((el: CyRow, index: number, arr: CyRow[]) => {
      const curYear = el.timeStamp.getFullYear();
      if (index === 0) {
        return curYear.toString();
      }
      const prevYear = arr[index - 1].timeStamp.getFullYear();
      if (curYear !== prevYear) {
        return curYear.toString();
      }
      return '';
    });
    return years;
  }

  activeColor(value: number) {
    if (value === 0) return '#434343';
    return '';
  }
}
