
import { Component, Vue } from 'vue-property-decorator';
import Table from './SubsidiariesTable.vue';
import Grid from './SubsidiariesGrid.vue';

@Component({
  components: {
    Table,
    Grid,
  },
})
export default class SubsidiariesPage extends Vue {
  public activeTab = 'table';

  mounted() {
    const companyName = this.$store.getters.companyInfo.companyName;
    // this.$store.dispatch('fetchSubsidiaries', companyName);
  }

  switchTableToGrid(event: any) {
    this.activeTab = event.target.innerText.toLowerCase();
  }

  get SubsidiariesData(): any[] {
    return this.$store.getters.companySubsidiaries;
  }
}
