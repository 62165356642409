import { render, staticRenderFns } from "./HistoricalData.vue?vue&type=template&id=4f8bd67e&scoped=true&"
import script from "./HistoricalData.vue?vue&type=script&lang=ts&"
export * from "./HistoricalData.vue?vue&type=script&lang=ts&"
import style0 from "./HistoricalData.vue?vue&type=style&index=0&id=4f8bd67e&prod&scoped=true&lang=scss&"
import style1 from "./HistoricalData.vue?vue&type=style&index=1&id=4f8bd67e&prod&lang=scss&"
import style2 from "./HistoricalData.vue?vue&type=style&index=2&id=4f8bd67e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f8bd67e",
  null
  
)

export default component.exports