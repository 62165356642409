import { Chart } from '../../../../../libs/chart-lib/index';
import { Ticks } from '../../../../../libs/chart-lib/index';
import bezier from 'bezier-easing';

// common decoration variables
const yTicksDrawOptions: [number, number, string] = [-4, 1, '#FFFFFF80'];
const yGridDrawOptions: [boolean, string, number, number[]] = [true, '#FFFFFF33', 1, [1, 2]];

export let chart: Chart;

export function createChart_3(container: HTMLElement, data: any[]) {
  const [
    row_markets,
    row_exits,
    row_1,
    row_2,
    markets,
    markets_values_lables,
    exits,
    exits_values_lables,
  ] = prepareDataRows(data);
  const xMax = row_markets.length;
  chart = new Chart(container, [0, xMax], [0, 10]);
  const { xAxis, yAxis } = chart;

  // ось X
  xAxis.display = false;
  xAxis.ticks.label.setOptions(false);

  // ось Y
  yAxis.setOptions('end');
  yAxis.ticks.settickDrawOptions(...yTicksDrawOptions);
  yAxis.ticks.setOptions(true, 'fixedCount', [2]);
  yAxis.ticks.label
    .setOptions(true, '#B2B2B2', 'right', 0)
    .setFontOptions(12, 'Inter', 'normal')
    .setCenterX(0)
    .setOffset(10, 0);
  yAxis.ticks.setCustomLabels(['LOW', 'MEDIUM', 'HIGH']);
  yAxis.grid.setOptions(...yGridDrawOptions);

  // создаем Plots
  chart.addPlot('bar-chart-markets', 'bar_chart', 1, '#272727', '#654131', 2); // markets
  chart.addPlot('bar-chart-exits', 'bar_chart', 1, '#272727', '#372B25', 2); // exits

  // создаем Series
  chart.addSeriesRow('bar-chart-2', [row_2]).setPlotsIds('bar-chart-exits');
  chart.addSeriesRow('bar-chart-1', [row_1]).setPlotsIds('bar-chart-markets');

  // создаем Tooltips
  chart
    .findPlotById('bar-chart-markets')
    ?.addTooltip('ttId', 'bar_chart_highlighter', 1, '#272727', '#C26740', 2);
  chart
    .findPlotById('bar-chart-exits')
    ?.addTooltip('ttId', 'bar_chart_highlighter', 1, '#272727', '#503123', 2);
  chart
    .findPlotById('bar-chart-exits')
    ?.addTooltip('ttId', 'bar_chart_fullheight', 1, '#272727', '#FFFFFF1A', 2);

  chart
    .findPlotById('bar-chart-exits')
    ?.addTooltip('ttId_1', 'simple_label_square_markers', 0, '#C26740', '#C26740', 5, markets)
    .label.setOptions(true, '#FFFFFF99', 'top', 0)
    .setFontOptions(11, 'Inter', 'normal')
    .setCenterX(0)
    .setOffset(-20, 10);

  chart
    .findPlotById('bar-chart-exits')
    ?.addTooltip('ttId_2', 'simple_label', 0.5, '#F9F9F9', '#F9F9F9', markets_values_lables)
    .label.setOptions(true, '#FFFFFF', 'top', 0, ['11', '"Inter"'])
    .setCenterX(0)
    .setOffset(25, 10);

  chart
    .findPlotById('bar-chart-exits')
    ?.addTooltip('ttId_3', 'simple_label_square_markers', 0, '#503123', '#503123', 5, exits)
    .label.setOptions(true, '#FFFFFF99', 'top', 0)
    .setFontOptions(11, 'Inter', 'normal')
    .setCenterX(0)
    .setOffset(-20, 30);

  chart
    .findPlotById('bar-chart-exits')
    ?.addTooltip('ttId_4', 'simple_label', 0.5, '#F9F9F9', '#F9F9F9', exits_values_lables)
    .label.setOptions(true, '#FFFFFF', 'top', 0, ['11', '"Inter"'])
    .setCenterX(0)
    .setOffset(25, 30);

  // настраиваем Min Max осей
  xAxis.setMinMaxStatic(chart.data.findExtremes('val')); //по экстремумам оси X
  xAxis.setMinMaxStatic([chart.xAxis.min - 0.5, chart.xAxis.max + 0.5]);

  // turn on animations
  chart.yAxis.ticks.switchAnimation(true, 300);
  chart.switchDataAnimation(true, 300);
  const easing = bezier(0.65, 0, 0.35, 1);
  chart.data.changeAllSeriesAnimationTimeFunction(easing);
  chart.setCanvasPaddings(50, 65, 8, 25);

  chart.refresh();
  return chart;
}

export const changeRange = (data: any[]) => {
  const [
    row_markets,
    row_exits,
    row_1,
    row_2,
    markets,
    markets_values_lables,
    exits,
    exits_values_lables,
  ] = prepareDataRows(data);

  // change Series data
  chart.data.findSeriesById('bar-chart-2')?.replaceSeriesData([row_2], false);
  chart.data.findSeriesById('bar-chart-1')?.replaceSeriesData([row_1], false);

  // change Tooltips labels
  chart
    .findPlotById('bar-chart-exits')
    ?.findTooltipById('ttId_1')
    ?.setOptions([0, '#C26740', '#C26740', 5, markets]);
  chart
    .findPlotById('bar-chart-exits')
    ?.findTooltipById('ttId_2')
    ?.setOptions([0.5, '#F9F9F9', '#F9F9F9', markets_values_lables]);
  chart
    .findPlotById('bar-chart-exits')
    ?.findTooltipById('ttId_3')
    ?.setOptions([0, '#503123', '#503123', 5, exits]);
  chart
    .findPlotById('bar-chart-exits')
    ?.findTooltipById('ttId_4')
    ?.setOptions([0.5, '#F9F9F9', '#F9F9F9', exits_values_lables]);

  // change Axis minmax
  chart.xAxis.setMinMax(chart.data.findExtremes('val'), false);
  chart.xAxis.setMinMax([chart.xAxis.min - 0.5, chart.xAxis.max + 0.5], true);
};

const prepareDataRows = (data: any[]) => {
  const [row_markets, row_exits] = [...data];
  const row_1 = row_markets.map((el: any) => el);
  const row_2 = row_markets.map((el: any, index: number) => el + row_exits[index]);
  const markets = row_2.map(() => {
    return 'Markets';
  });
  const markets_values_lables = row_markets.map((el: any) => {
    return el.toFixed(2);
  });
  const exits = row_2.map(() => {
    return 'Exits';
  });
  const exits_values_lables = row_exits.map((el: any) => {
    return el.toFixed(2);
  });
  return [
    row_markets,
    row_exits,
    row_1,
    row_2,
    markets,
    markets_values_lables,
    exits,
    exits_values_lables,
  ];
};
