import { Chart } from '../../../../../libs/chart-lib/index';
import { Ticks } from '../../../../../libs/chart-lib/index';
import bezier from 'bezier-easing';

// common decoration variables
const yTicksDrawOptions: [number, number, string] = [-4, 1, '#FFFFFF80'];
const yGridDrawOptions: [boolean, string, number, number[]] = [true, '#FFFFFF33', 1, [1, 2]];

export let chart: Chart;

export function createChart_4(container: HTMLElement, data: any[]) {
  let row_clients: number[] = [];
  [row_clients] = [...data];
  const xMax = row_clients.length;
  chart = new Chart(container, [0, xMax], [0, 10]);

  const { xAxis, yAxis } = chart;

  // ось X
  xAxis.display = false;
  xAxis.ticks.label.setOptions(false);

  // ось Y
  yAxis.setOptions('end');
  yAxis.ticks.settickDrawOptions(...yTicksDrawOptions);
  yAxis.ticks.setOptions(true, 'fixedCount', [2]);
  yAxis.ticks.label
    .setOptions(true, '#B2B2B2', 'right', 0)
    .setFontOptions(12, 'Inter', 'normal')
    .setCenterX(0)
    .setOffset(10, 0);
  yAxis.ticks.setCustomLabels(['LOW', 'MEDIUM', 'HIGH']);
  yAxis.grid.setOptions(...yGridDrawOptions);

  // создаем Plots
  chart.addPlot('bar-chart-clients', 'bar_chart', 1, '#272727', '#6A573B', 2); // clients

  // создаем Series
  chart.addSeriesRow('bar-chart-1', [row_clients]).setPlotsIds('bar-chart-clients');

  // создаем Tooltips
  chart
    .findPlotById('bar-chart-clients')
    ?.addTooltip('ttId', 'bar_chart_highlighter', 1, '#272727', '#CFA058', 2);
  chart
    .findPlotById('bar-chart-clients')
    ?.addTooltip('ttId', 'bar_chart_fullheight', 1, '#272727', '#FFFFFF1A', 2);

  const clients = row_clients.map((el) => {
    return 'Clients';
  });
  chart
    .findPlotById('bar-chart-clients')
    ?.addTooltip('ttId_1', 'simple_label_square_markers', 0, '#CFA058', '#CFA058', 5, clients)
    .label.setOptions(true, '#FFFFFF99', 'top', 0)
    .setFontOptions(11, 'Inter', 'normal')
    .setCenterX(0)
    .setOffset(-20, 10);

  const clients_values_lables = row_clients.map((el) => {
    return el.toFixed(2);
  });
  chart
    .findPlotById('bar-chart-clients')
    ?.addTooltip('ttId_2', 'simple_label', 0.5, '#F9F9F9', '#F9F9F9', clients_values_lables)
    .label.setOptions(true, '#FFFFFF', 'top', 0, ['11', '"Inter"'])
    .setCenterX(0)
    .setOffset(25, 10);

  // настраиваем Min Max осей
  xAxis.setMinMaxStatic(chart.data.findExtremes('val'));
  xAxis.setMinMaxStatic([chart.xAxis.min - 0.5, chart.xAxis.max + 0.5]);

  chart.yAxis.ticks.switchAnimation(true, 300);
  chart.switchDataAnimation(true, 300);
  const easing = bezier(0.65, 0, 0.35, 1);
  chart.data.changeAllSeriesAnimationTimeFunction(easing);
  chart.setCanvasPaddings(30, 65, 8, 25);
  chart.refresh();
  return chart;
}

export const changeRange = (data: any[]) => {
  const [row] = [...data];
  chart.data.findSeriesById('bar-chart-1')?.replaceSeriesData([row], false);
  const clients = row.map(() => {
    return 'Clients';
  });
  chart
    .findPlotById('bar-chart-clients')
    ?.findTooltipById('ttId_1')
    ?.setOptions([0, '#CFA058', '#CFA058', 5, clients]);
  const clients_values_lables = row.map((el: any) => {
    return el.toFixed(2);
  });
  chart
    .findPlotById('bar-chart-clients')
    ?.findTooltipById('ttId_2')
    ?.setOptions([0.5, '#F9F9F9', '#F9F9F9', clients_values_lables]);
  chart.xAxis.setMinMax(chart.data.findExtremes('val'), false);
  chart.xAxis.setMinMax([chart.xAxis.min - 0.5, chart.xAxis.max + 0.5], true);
};
