import { Chart } from '../../../../../libs/chart-lib/index';
import { Ticks } from '../../../../../libs/chart-lib/index';
import bezier from 'bezier-easing';

// common decoration variables
const yTicksDrawOptions: [number, number, string] = [-4, 1, '#FFFFFF80'];
const yGridDrawOptions: [boolean, string, number, number[]] = [true, '#FFFFFF33', 1, [1, 2]];
const canvasPaddings = [0, 65, 8, 25];

export let chart: Chart;

export function createChart_2(container: HTMLElement, data: any[]) {
  // prepare Data rows
  const [
    row_forums,
    row_pastes,
    row_osint,
    row_1,
    row_2,
    row_3,
    forums,
    forums_values_lables,
    pastes,
    pastes_values_lables,
    osint,
    osint_values_lables,
  ] = prepareDataRows(data);

  const xMax = row_forums.length;
  chart = new Chart(container, [0, xMax], [0, 10]);
  const { xAxis, yAxis } = chart;

  // ось X
  xAxis.display = false;
  xAxis.ticks.label.setOptions(false);

  // ось Y
  yAxis.setOptions('end');
  yAxis.ticks.settickDrawOptions(...yTicksDrawOptions);
  yAxis.ticks.setOptions(true, 'fixedCount', [2]);
  yAxis.ticks.label
    .setOptions(true, '#B2B2B2', 'right', 0)
    .setFontOptions(12, 'Inter', 'normal')
    .setCenterX(0)
    .setOffset(10, 0);
  yAxis.ticks.setCustomLabels(['LOW', 'MEDIUM', 'HIGH']);
  yAxis.grid.setOptions(...yGridDrawOptions);

  // создаем Plots
  chart.addPlot('bar-chart-forums', 'bar_chart', 1, '#272727', '#514568', 2); // forums
  chart.addPlot('bar-chart-pastes', 'bar_chart', 1, '#272727', '#5B3F9566', 2); // pastes
  chart.addPlot('bar-chart-osint', 'bar_chart', 1, '#272727', '#5C5C5C66', 2); // osint

  // создаем Series
  chart.addSeriesRow('bar-chart-3', [row_3]).setPlotsIds('bar-chart-osint');
  chart.addSeriesRow('bar-chart-2', [row_2]).setPlotsIds('bar-chart-pastes');
  chart.addSeriesRow('bar-chart-1', [row_1]).setPlotsIds('bar-chart-forums');

  // создаем Tooltips
  chart
    .findPlotById('bar-chart-forums')
    ?.addTooltip('ttId', 'bar_chart_highlighter', 1, '#272727', '#8F73CA', 2);
  chart
    .findPlotById('bar-chart-pastes')
    ?.addTooltip('ttId', 'bar_chart_highlighter', 1, '#272727', '#5B3F95', 2);
  chart
    .findPlotById('bar-chart-osint')
    ?.addTooltip('ttId', 'bar_chart_highlighter', 1, '#272727', '#5C5C5C', 2);
  chart
    .findPlotById('bar-chart-osint')
    ?.addTooltip('ttId', 'bar_chart_fullheight', 1, '#272727', '#FFFFFF1A', 2);

  chart
    .findPlotById('bar-chart-osint')
    ?.addTooltip('ttId_1', 'simple_label_square_markers', 0, '#8F73CA', '#8F73CA', 5, forums)
    .label.setOptions(true, '#FFFFFF99', 'top', 0)
    .setFontOptions(11, 'Inter', 'normal')
    .setCenterX(0)
    .setOffset(-20, 10);

  chart
    .findPlotById('bar-chart-osint')
    ?.addTooltip('ttId_2', 'simple_label', 0.5, '#F9F9F9', '#F9F9F9', forums_values_lables)
    .label.setOptions(true, '#FFFFFF', 'top', 0, ['11', '"Inter"'])
    .setCenterX(0)
    .setOffset(25, 10);

  chart
    .findPlotById('bar-chart-osint')
    ?.addTooltip('ttId_3', 'simple_label_square_markers', 0, '#5B3F95', '#5B3F95', 5, pastes)
    .label.setOptions(true, '#FFFFFF99', 'top', 0)
    .setFontOptions(11, 'Inter', 'normal')
    .setCenterX(0)
    .setOffset(-20, 30);

  chart
    .findPlotById('bar-chart-osint')
    ?.addTooltip('ttId_4', 'simple_label', 0.5, '#F9F9F9', '#F9F9F9', pastes_values_lables)
    .label.setOptions(true, '#FFFFFF', 'top', 0, ['11', '"Inter"'])
    .setCenterX(0)
    .setOffset(25, 30);

  chart
    .findPlotById('bar-chart-osint')
    ?.addTooltip('ttId_5', 'simple_label_square_markers', 0, '#5B3F95', '#5B3F95', 5, osint)
    .label.setOptions(true, '#FFFFFF99', 'top', 0)
    .setFontOptions(11, 'Inter', 'normal')
    .setCenterX(0)
    .setOffset(-20, 50);

  chart
    .findPlotById('bar-chart-osint')
    ?.addTooltip('ttId_6', 'simple_label', 0.5, '#F9F9F9', '#F9F9F9', osint_values_lables)
    .label.setOptions(true, '#FFFFFF', 'top', 0, ['11', '"Inter"'])
    .setCenterX(0)
    .setOffset(25, 50);

  // настраиваем Min Max осей
  xAxis.setMinMaxStatic(chart.data.findExtremes('val')); //по экстремумам оси X
  xAxis.setMinMaxStatic([chart.xAxis.min - 0.5, chart.xAxis.max + 0.5]);

  // turn on animations
  chart.yAxis.ticks.switchAnimation(true, 300);
  chart.switchDataAnimation(true, 300);
  const easing = bezier(0.65, 0, 0.35, 1);
  chart.data.changeAllSeriesAnimationTimeFunction(easing);
  chart.setCanvasPaddings(70, 65, 8, 25);
  chart.refresh();
  return chart;
}

export const changeRange = (data: any[]) => {
  const [
    row_forums,
    row_pastes,
    row_osint,
    row_1,
    row_2,
    row_3,
    forums,
    forums_values_lables,
    pastes,
    pastes_values_lables,
    osint,
    osint_values_lables,
  ] = prepareDataRows(data);

  // change Series data
  chart.data.findSeriesById('bar-chart-3')?.replaceSeriesData([row_3], false);
  chart.data.findSeriesById('bar-chart-2')?.replaceSeriesData([row_2], false);
  chart.data.findSeriesById('bar-chart-1')?.replaceSeriesData([row_1], false);

  // change Tooltips labels
  chart
    .findPlotById('bar-chart-osint')
    ?.findTooltipById('ttId_1')
    ?.setOptions([0, '#8F73CA', '#8F73CA', 5, forums]);
  chart
    .findPlotById('bar-chart-osint')
    ?.findTooltipById('ttId_2')
    ?.setOptions([0.5, '#F9F9F9', '#F9F9F9', forums_values_lables]);
  chart
    .findPlotById('bar-chart-osint')
    ?.findTooltipById('ttId_3')
    ?.setOptions([0, '#5B3F95', '#5B3F95', 5, pastes]);
  chart
    .findPlotById('bar-chart-osint')
    ?.findTooltipById('ttId_4')
    ?.setOptions([0.5, '#F9F9F9', '#F9F9F9', pastes_values_lables]);
  chart
    .findPlotById('bar-chart-osint')
    ?.findTooltipById('ttId_5')
    ?.setOptions([0, '#5B3F95', '#5B3F95', 5, osint]);
  chart
    .findPlotById('bar-chart-osint')
    ?.findTooltipById('ttId_6')
    ?.setOptions([0.5, '#F9F9F9', '#F9F9F9', osint_values_lables]);

  // change Axis minmax
  chart.xAxis.setMinMax(chart.data.findExtremes('val'), false);
  chart.xAxis.setMinMax([chart.xAxis.min - 0.5, chart.xAxis.max + 0.5], true);
};

const prepareDataRows = (data: any[]) => {
  const [row_forums, row_pastes, row_osint] = [...data];
  const row_1 = row_forums.map((el: any, index: number) => el);
  const row_2 = row_forums.map((el: any, index: number) => el + row_pastes[index]);
  const row_3 = row_forums.map(
    (el: any, index: number) => el + row_pastes[index] + row_osint[index]
  );
  const forums = row_2.map((el: any) => {
    return 'Forums';
  });
  const forums_values_lables = row_forums.map((el: any) => {
    return el.toFixed(2);
  });
  const pastes = row_2.map((el: any) => {
    return 'Pastes';
  });
  const pastes_values_lables = row_pastes.map((el: any) => {
    return el.toFixed(2);
  });
  const osint = row_3.map((el: any) => {
    return 'OSINT';
  });
  const osint_values_lables = row_osint.map((el: any) => {
    return el.toFixed(2);
  });
  return [
    row_forums,
    row_pastes,
    row_osint,
    row_1,
    row_2,
    row_3,
    forums,
    forums_values_lables,
    pastes,
    pastes_values_lables,
    osint,
    osint_values_lables,
  ];
};
