
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Meta } from '@/types/meta';

import MetaLabel from '@/components/MetaLabel.vue';

@Component({
  components: {
    MetaLabel,
  },
})
export default class Indicator extends Vue {
  @Prop() valueMeta!: Meta | null;
  @Prop() type!: 'text' | 'number';

  get value() {
    if (!this.valueMeta) return null;
    return this.valueMeta.value;
  }

  get displayedValue() {
    if (!this.value) return '-';
    try {
      const result = this.value.toFixed(2);
      return result;
    } catch {
      return this.value;
    }
  }

  setIndicatorsColor(value: any) {
    const period = isNaN(value) || value === null ? -1 : value;
    return {
      'factors__indicator-value-red': period >= 0 && period < 3,
      'factors__indicator-value-yellow': period >= 3 && period < 4,
      'factors__indicator-green': period >= 4,
    };
  }

  setTextIndicatorsColor(value: any) {
    return {
      'factors__indicator-value-red': value === 'high',
      'factors__indicator-value-yellow': value === 'avg',
      'factors__indicator-green': value === 'low',
    };
  }

  setTextIndicatorsValue(value: any) {
    let indicator = '';

    if (isNaN(value) || value === null) return '-';

    if (value >= 4 && value <= 5) {
      indicator = 'low';
    }

    if (value >= 3 && value <= 4) {
      indicator = 'avg';
    }

    if (value >= 0 && value < 3) {
      indicator = 'high';
    }

    return indicator;
  }
}
