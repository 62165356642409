import { Chart, Legend, Point, Rectangle, Ticks } from '../../../../../libs/chart-lib/index';

import bezier from 'bezier-easing';

export let chart: Chart;

// @ts-ignore
export function createChart(container, data) {
  // @ts-ignore
  chart = new Chart(container, [0, 5], [0, 5]);

  const [x, y, oneX, oneY] = [...data];

  chart.xAxis.setOptions('end', 1, 'black');
  chart.xAxis.ticks.setOptions(false, 'fixedCount', 5);
  chart.xAxis.ticks.label.setOptions(false, '#B2B2B2', 'bottom', 11, ['12', '"Inter"']);
  chart.xAxis.grid.setOptions(true, '#ffffff80', 0.5, []);

  chart.yAxis.setOptions('end', 1, '#B2B2B2');
  chart.yAxis.ticks.setOptions(false, 'fixedCount', 5);
  chart.yAxis.ticks.label.setOptions(false, '#B2B2B2', 'right', 20, ['12', '"Inter"']);
  chart.yAxis.grid.setOptions(true, '#ffffff80', 0.5, []);

  chart.hasBorder = true;
  chart.addPlot('uni_circles', 'unicode', 9, '#ffffff', '●');
  chart.addPlot('uni_triangle', 'unicode', 16, '#ffffff', '▼');

  chart.addSeries('portfolio', [x, y]).setPlotsIds('uni_circles');
  chart.addSeries('portfolio_1', [oneX, oneY]).setPlotsIds('uni_triangle');
  const easing = bezier(0.65, 0, 0.35, 1);
  chart.xAxis.ticks.switchAnimation(false, 300);
  chart.yAxis.ticks.switchAnimation(false, 300);
  chart.switchDataAnimation(true, 300);
  chart.data.changeAllSeriesAnimationTimeFunction(easing);
  chart.setCanvasPaddings(5, 5, 5, 5);
  chart.switchResolution();
  chart.addBackGround('coloredGrid_cbh_v2');
  chart.refresh();
}

export function reorganizeChart(data: any) {
  const [x, y, oneX, oneY] = [...data];
  chart.data.findSeriesById('portfolio')?.replaceSeriesData([x, y], true);
  chart.data.findSeriesById('portfolio_1')?.replaceSeriesData([oneX, oneY], true);
}
