
import { Prop, Component, Vue } from 'vue-property-decorator';

@Component
export default class ErrorBoundary extends Vue {
  @Prop({ default: false }) readonly hideStack!: boolean;
  error: Partial<Error> = {};

  errorCaptured(err: Error) {
    console.error('ErrorBoundary', err);
    this.$set(this.error, 'message', err.message);
    this.$set(this.error, 'stack', err.stack);
    return false;
  }

  get hasError() {
    return Object.keys(this.error).length !== 0;
  }
}
