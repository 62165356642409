
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Highlights extends Vue {
  show = true;

  get highlights(): any {
    return this.$store.getters.companyFiDataHighlights;
  }

  get list1(): any[] {
    return [
      {
        fieldName: 'Market Capitalization',
        value: this.formatCurrencyValue(this.highlights.MarketCapitalization),
      },
      { fieldName: 'EBITDA', value: this.formatCurrencyValue(this.highlights.EBITDA) },
      { fieldName: 'PERatio', value: this.highlights.PERatio },
      { fieldName: 'PEGRatio', value: this.highlights.PEGRatio },
      {
        fieldName: 'Wall Street Target Price',
        value: this.formatCurrencyValue(this.highlights.WallStreetTargetPrice),
      },
      { fieldName: 'Book Value', value: this.formatCurrencyValue(this.highlights.BookValue) },
      {
        fieldName: 'Dividend Share',
        value: this.formatCurrencyValue(this.highlights.DividendShare),
      },
      {
        fieldName: 'Dividend Yield',
        value: this.formatCurrencyValue(this.highlights.DividendYield),
      },
    ];
  }

  get list2(): any[] {
    return [
      { fieldName: 'Earnings Share', value: this.highlights.EarningsShare },
      { fieldName: 'EPS Estimate Current Year', value: this.highlights.EPSEstimateCurrentYear },
      { fieldName: 'EPS Estimate Next Year', value: this.highlights.EPSEstimateNextYear },
      { fieldName: 'EPS Estimate Next Quarter', value: this.highlights.EPSEstimateNextQuarter },
      {
        fieldName: 'EPS Estimate Current Quarter',
        value: this.highlights.EPSEstimateCurrentQuarter,
      },
      { fieldName: 'Profit Margin', value: this.highlights.ProfitMargin },
      { fieldName: 'Operating Margin TTM', value: this.highlights.OperatingMarginTTM },
      { fieldName: 'Return On Assets TTM', value: this.highlights.ReturnOnAssetsTTM },
    ];
  }

  get list3(): any[] {
    return [
      { fieldName: 'Return On Equity TTM', value: this.highlights.ReturnOnEquityTTM },
      { fieldName: 'Revenue TTM', value: this.formatCurrencyValue(this.highlights.RevenueTTM) },
      { fieldName: 'Revenue Per Share TTM', value: this.highlights.RevenuePerShareTTM },
      {
        fieldName: 'Quarterly Revenue Growth YOY',
        value: this.highlights.QuarterlyRevenueGrowthYOY,
      },
      {
        fieldName: 'Gross Profit TTM',
        value: this.formatCurrencyValue(this.highlights.GrossProfitTTM),
      },
      { fieldName: 'Diluted Eps TTM', value: this.highlights.DilutedEpsTTM },
      {
        fieldName: 'Quarterly Earnings Growth YOY',
        value: this.highlights.QuarterlyEarningsGrowthYOY,
      },
    ];
  }

  numberSeparator(number: string): string {
    return number.replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, `$& `);
  }

  private formatCurrencyValue(value: number, dec: number = 2, power?: number): string {
    const postfix: string[] = ['', 'k', 'mn', 'bn', 'tn'];
    let counter: number = 0;
    let curValue = value;
    if (power) {
      curValue = curValue * 10 ** power;
    }
    let result: string = '';
    if (value < 0) {
      result = result + '-';
    }

    while (Math.abs(curValue) > 1000 && counter < postfix.length - 1) {
      curValue = curValue / 1000;
      counter = ++counter;
    }

    result = this.numberSeparator(Math.abs(curValue).toFixed(dec));
    return result + ' ' + postfix[counter] + ' ' + this.$store.getters.currencySymbol;
  }
}
