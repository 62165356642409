
import { Component, Prop, Vue } from 'vue-property-decorator';

import MenuItem from './CompanyMenuItem.vue';

@Component({
  components: {
    MenuItem,
  },
})
export default class CompanyMenu extends Vue {
  @Prop({ default: [] }) readonly companyRoutes!: any[];

  get menuData() {
    return this.companyRoutes;
  }
}
