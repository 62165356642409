
import { Component, Vue } from 'vue-property-decorator';
import OverviewPageNew from './index-new.vue';

@Component({
  components: {
    OverviewPageNew,
  },
})
export default class OverviewContainer extends Vue {}
