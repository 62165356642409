
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class HeaderMenuItem extends Vue {
  @Prop() private pageName!: string;
  @Prop() private path!: string;
  @Prop() private title!: string;

  get isSelected(): boolean {
    const find = this.$route.path.indexOf(this.path);
    if (find === -1) return false;
    return true;
  }
}
