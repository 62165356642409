
import { Component, Vue } from 'vue-property-decorator';
import { CyRow } from '@/types';

interface CyRowWithRisk extends CyRow {
  RaeRisk?: 'High' | 'Medium' | 'Low';
}

@Component
export default class CyDataTable extends Vue {
  headersTop: string[] = ['Level 1', 'Level 2', 'Level 3'];
  headers: string[] = ['Forums', 'Pastes', 'OSINT', 'Markets', 'Exits'];

  get tableData(): CyRowWithRisk[] {
    return this.$store.getters.cyDataListWithAlerts;
  }

  get yearCol(): string[] {
    const years: string[] = this.tableData.map((el: CyRow, index: number, arr: CyRow[]) => {
      const curYear = el.timeStamp.getFullYear();
      if (index === 0) {
        return curYear.toString();
      }
      const prevYear = arr[index - 1].timeStamp.getFullYear();
      if (curYear !== prevYear) {
        return curYear.toString();
      }
      return '';
    });
    return years;
  }

  activeColor(value: number) {
    if (value === 0) return '#434343';
    return '';
  }

  printValue(value: number) {
    if (value > 1) return value.toFixed(0);
    if (value === 0) return value.toFixed(0);
    return value.toFixed(2);
  }
}
