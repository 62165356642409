
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class CurrencyControl extends Vue {
  get currencyTable() {
    return this.$store.getters.currencyTable;
  }

  updateCurrencyValue(item: any) {
    this.$store.commit('setCurrencyItem', item);
  }
}
