
import { Component, Vue } from 'vue-property-decorator';
import Table from './CyFiScoresTable.vue';

@Component({
  components: {
    Table,
  },
})
export default class CyFiScoresPage extends Vue {
  private colorOptionsBasic = [
    { color: '#6464641C', condition: (val: number) => isNaN(val) },
    { color: '#D93F3059', condition: (val: number) => val >= 0 && val <= 2 },
    { color: '#ECBA4459', condition: (val: number) => val > 2 && val < 4 },
    { color: '#3D894C80', condition: (val: number) => val >= 4 && val <= 5 },
  ];

  mounted(): void {
    // this.$store.dispatch('fetchCyData')
  }
}
